//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import "./employees.css";
import CreateEmployees from "./utilities/CreateEmployees";
import EditEmployees from "./utilities/EditEmployees";
import { delete_employess, get_employess } from "../../services/humanResources/humanResources";
import TableContainer from "../../main_components/Table/TableContainer";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

const ConsultEmployees= () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;

  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(()=>{
    handleSubmit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}

//proceso de consultar todos los empleados
const handleSubmit = async () => {
    console.log("Entra a consultar todos los empleados");
    if(entrada===0){
      entrada=entrada+1;
      setmenssage("Consultando Empleados...");
      setmodalGeneral(true);
      setmodalT(true);
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_employess();
            setData(d === null ? [] : d.data);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }  
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err); 
    }
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={() => {
               CustomDialog(               
            <div>
              <CreateEmployees handleSubmit={handleSubmit} reconsultar={reconsultar} alertas={alertas}></CreateEmployees>
            </div>,
           {
             className: "modalpuntosventa",
             title:"Crear Empleado",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Empleado"
            
            alt=""
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ row }) => {
      
      const DeleteRuta = async () =>{  
      
      setmodalT(true);
      setData([]);
      setopen(true);
        try {
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                const dt = await delete_employess(row.original.id);
                console.log(dt);
                alertas("Empleado Eliminado", true);
                handleSubmit();
              }catch(err){
                console.log("error", err);
                setopen(false);
                alertas("Error, no se pudo eliminar el empleado", false);
                setmodalT(false);
                handleSubmit();
              }
            }, 1000);
          }else{}
        } catch (err) {
          console.log(err);
        }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de Eliminar el Empleado','Eliminar Empleado','Si','No');
                  if(result){
                    DeleteRuta()
                    }
                  }            
                }
              
              title="Eliminar Ruta"
              alt=""
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={() => {
                   CustomDialog(               
                 <div>
                    <EditEmployees row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></EditEmployees>
                 </div>,
               {
                 className: "modalpuntosventa",
                 title:"Editar Ruta",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              
              title="Editar Ruta"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
       Header:"Código",
       accessor:"code"
    },
    {
      Header:"Nombre",
      accessor:"name"
    },
    {
      Header:"RFC",
      accessor:"rfc"
    },
    {
      Header:"Puesto",
      accessor:"job_title"
    },
    {
      Header:"Telefono",
      accessor:"company_cell_phone_1"
    },
    {
      Header:"Correo",
      accessor:"company_email"
    }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>
       <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR EMPLEADOS", path: null},
                ]
              }
      ></BREADCRUMBS>
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
  
<div className="TableEmpleados">
  <div className="">         
    <>
    
          <StickyTable>
            <TableContainer
              paginado={"Empleados"}
              consultas={"Empleados consultados"}
              nametable={"Empleados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
       
          </div>
        </div>
    </>
  );
}

export default ConsultEmployees;
