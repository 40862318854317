//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal"
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";

import { delete_route, get_route } from "../../services/configuration/configuration";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import TableContainer from "../../main_components/Table/TableContainer";
//ESTILOS
import "./rutas.css";
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import OPERATIONES_ROUTES from "./tools/op_routes";

const ROUTES = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);



  useEffect(()=>{
    handleSubmit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}

const handleSubmit = async () => {
    if(entrada===0){
      entrada=entrada+1;
      setmenssage("Consultando Rutas...");
      setmodalGeneral(true);
      setmodalT(true);
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);

    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_route();
            setData(d === null ? [] : d.data.routes);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}   
    } catch (err) {
      console.log(err);
    }
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={() => {
              CustomDialog(               
            <div>
              <OPERATIONES_ROUTES operation={"CREATE"} handleSubmit={handleSubmit} reconsultar={reconsultar} alertas={alertas}/>
            </div>,
           {
             className: "modalpuntosventa",
             title:"Crear Ruta",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Ruta"
            
            alt=""
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ row }) => {
      
      const DeleteRuta = async () => {  
      
      setmodalT(true);
      setData([]);
      setopen(true);
        try {
          var rf_token = await refresh_token();
          if(rf_token === true){
            setTimeout(async() => {
              try{
                const dt = await delete_route(row.original.id);
                console.log(dt);
                alertas("Ruta Eliminada", true);
                handleSubmit();   
              }catch(err){
                console.log("error", err);
                setopen(false);
                if(err?.response?.status===409){
                  alertas("No se puede elminar la ruta, aún quedan puntos de venta asignados a la ruta", false);
                }else{
                  alertas("Error, no se pudo eliminar la Ruta", false);
                }
                setmodalT(false);
                handleSubmit();
              }   
            }, 1000);
          }else{}
         } catch (err) {
          console.log(err);
        }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de Eliminar la Ruta','Eliminar Ruta','Si','No');
                  if(result){
                    DeleteRuta()
                    }
                  }            
                }
              
              title="Eliminar Ruta"
              alt=""
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={
                () => {
                  CustomDialog(               
                 <div>
                    <OPERATIONES_ROUTES operation={"EDIT"} row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}/>
                 </div>,
               {
                 className: "modalpuntosventa",
                 title:"Editar Ruta",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              
              title="Editar Ruta"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Ruta",
      accessor:"route"
    },
    {
      Header:"Almacen",
      accessor:"name_warehouse"
    },
    {
      Header:"Tipo Venta",
      accessor:"sale_type"
    },
    {
      Header:"Descripción",
      accessor:"description"
    },
    {
      Header:"Código",
      accessor:"code"
    },
    {
      Header:"Vendedor",
      accessor:"name_user"
    },
    {
      Header:"Repartidor",
      accessor:"name_second_user"
    },
    {
      Header:"Días Entrega",
      accessor:"days_for_delivery"
    },
    {
      Header:"Código DMS",
      accessor:"code_dms"
    }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>
    <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR RUTAS", path: null},
                ]
              }
      ></BREADCRUMBS>

<div className="TableRutas">
  <div className="tablerutsp">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    <>
          <StickyTable>
            <TableContainer
              paginado={"Rutas"}
              consultas={"Rutas Consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
       
          </div>
        </div>
    </>
  );
}

export default ROUTES;
