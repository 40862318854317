//PAQUETERIAS
import { useEffect, useState, useMemo } from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal"
//COMPONENTES
import SubTableContainer from "./SubTableContainer";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
//IMAGENES
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import EditDetails from "./utilities/EditDetails";
import AddproductPromotion from "./utilities/AddproductPromotion";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { delete_promotion_details, get_promotions_details } from "../../services/businesspartners/businesspartners";

function SubTable (props) {

const [data, setData] = useState([]);
const [open, setopen] = useState(false);
  
useEffect(() => {
  detallespromotions();
},[]);

useEffect(() => {
  const handle = () => {
    setData(data);
  };
   handle();
},);


const detallespromotions = async () => {  
  setData([]);
  setopen(true);
  try {
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          var d = await get_promotions_details(sessionStorage.getItem("id_detalles"));
          setData(d === null ? [] : d.data.promotionsDetailsList); 
          setopen(false);  
        }catch(err){
          setData([]);
          setopen(false)
        }
      }, 1000);
    }else{}
  } catch (error) {} 
}

  const columns = useMemo(
    () => [
      {
        Header:()=>(
          <div>
            <img
              id="Agregardetalles"
              src={CREATE}
              onClick={async () => {
                const result = await CustomDialog(               
               <div>
                  <AddproductPromotion detallespromotions={detallespromotions} reconsultar={props.reconsultar} alertas={props.alertas}></AddproductPromotion>
               </div>,
             {
               className: "modalpuntosventa",
               title:"Agregar Detalles",
               showCloseIcon: true,
               isCanClose:false,
             }
         );
       }}
              title="Agregar Detalles"
              alt=""
            ></img>
          </div>
        ),
      id:"AccionesB",
      Cell: ({ row }) => {  

      const deletedetails = async () => {

        props.reconsultar(true);
        setData([]);
        setopen(true);
        try {
         var rf_token = await refresh_token();
         if(rf_token===true){
          setTimeout(async() => {
            try{
              const dt = await delete_promotion_details(row.original.id_promotios_fk,row.original.id );
              console.log(dt);
              props.alertas("Producto Relacionado a la Promoción Eliminado", true);
               detallespromotions();
               props.reconsultar(false); 
            }catch(err){
              console.log("error", err);
              props.alertas("No se pudo eliminar el producto relacionado a la promoción", false);
              props.reconsultar(false);
           }     
          }, 1000);
         }else{}
         } catch (err) {
          console.log(err);
         }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el producto relacionado a la promoción?','Eliminar Producto','Si','No');
                  if(result){
                    deletedetails();
                    }
                  }            
                }
              
              title="Eliminar Producto"
              alt=""
            ></img>
            <img
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                    <EditDetails detallespromotions={detallespromotions} row={row} reconsultar={props.reconsultar} alertas={props.alertas}></EditDetails>
                 </div>,
               {
                 className: "modalTitle",
                 title:"Editar Producto",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
          detallespromotions();
         }
        }
              
              title="Editar Producto"
              alt=""
        
            ></img>
          </div>
        );
      }
      },
      {
        Header:"Código",
        accessor: "product_code"
      },
      {
        Header:"Nombre",
        accessor:"product_name"
      },
      {
        Header:"Precio",
        accessor: (d) => {
          return d.promo_price
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.promo_price)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.promo_price);
        },          
      },
      {
        Header:"Cantidad",
        accessor:"quantity"
      }
    ],
    []
  );

  return (
    <div className="TableConsultarTodo2">  
            <StickyTable>
              <SubTableContainer
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                Gmodal={open}
              />
            </StickyTable>
    </div>
  );
};

export default SubTable;
