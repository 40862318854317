//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import NotificationSystem from 'react-notification-system';
//COMPONENTES
import TableContainer from "./Table_Container_Tax";

import SubTable from "./Get_Tax_Concept";
import Put_concept from "./Put_Concept";
import Set_Tax_Concept from "./Set_Tax_Concept";
import { delete_concepts, get_concept_config } from "../../../services/invoices/invoice";
//ESTILOS
import "../../estilo.css";
import "./tableclientes.css";
import "../../modal.css";
//IMAGENES/ICONOS
import SAVE from "../../../imgComponents/save.png";
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import CREATE from "../../../imgComponents/create.png";
import ADD from "../../../imgComponents/add.png";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import SubTable_DV2 from "./Get_Tax_Concept_v2";
import SET_CONCEPT_V2 from "./tools/set_concept_v2";
import { update_null_identifiers } from "../../../main_components/methods_v2/auxiliar_funtion";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CONSUL_CONCEPT_V2 = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [modalT, setmodalT] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const notificationSystemRef = useRef();
  const [total_rows, settotal_rows] = useState("");


  const handleSubmit = async () => {
    setIsLoading(true);
    setmodalT(true);
    setMenssage("Consultando conceptos...");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_concept_config();
            var ndt = update_null_identifiers(dt?.data, update_identifiers);
            setData(ndt);
            setmodalT(false);
            setIsLoading(false);
            setMenssage("");
          } catch (err) {
            console.log("error", err);
            setData([]);
            setIsLoading(false);
            setmodalT(false);
            setMenssage("");
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const columns = useMemo(
    () => [
      {
        id: "concept",
        header: "Concepto",
        accessorKey: "concept",
        size: 300,
      },
      {
        id: "descripcion",
        header: "Descripción",
        accessorKey: "descripcion",
        size: 300,
      },
      {
        id: "valor_unitario",
        header: "Valor Unitario",
        accessorKey: "valor_unitario",
      },
      {
        id: "clave_prod_serv",
        header: "Clave Producto Servicio",
        accessorKey: "clave_prod_serv",
      },
      {
        id: "clave_unidad",
        header: "Clave Unidad",
        accessorKey: "clave_unidad",
      },
      {
        id: "unidad",
        header: "Unidad",
        accessorKey: "unidad",
      },
      {
        id: "no_identificacion",
        header: "Número de Identificación",
        accessorKey: "no_identificacion",
      },
    ],
    []
  );


  const update_identifiers =
    [
      { concept: 'string' },
      { descripcion: 'string' },
      { valor_unitario: 'String' },
      { clave_prod_serv: 'string' },
      { clave_unidad: 'string' },
      { unidad: 'string' },
      { no_identificacion: 'string' }
    ];


  const [rec, setrec] = useState(false);

  return (
    <div >
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR CONCEPTOS", path: null },
        ]
      }
      old_version={true}
      modul="CONSULTAR CONCEPTOS V1"
      path={"/consult_concept"}
     
       cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <div>
        <MODAL_TABLE
          open={true}
          message={""}
          modalGeneral={false}
          modalT={modalT}
        ></MODAL_TABLE>
      </div>
      <div className="new_table_v2" style={{"width":"95%", "margin":"0 auto"}}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          onStateChange={handleStateChange}
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    title="Crear Concepto"
                    src={CREATE}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <SET_CONCEPT_V2
                          method={"CREATE"}
                          setmodalT={setmodalT}
                          handleSubmit={handleSubmit}
                          alertas={alertas}
                        ></SET_CONCEPT_V2>,
                        {
                          className: "modalTItle",
                          title: "Seleccione la configuracion del concepto",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderDetailPanel={({ row }) => {
            return (
              <SubTable_DV2
                id={row?.original?.id}
                setmodalT={setmodalT}
                alertas={alertas}
                rec={rec}
                setrec={setrec}
              />
            )
          }}

          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              row={row}
              handleSubmit={handleSubmit}
              alertas={alertas}
              setrec={setrec}
            />
          ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Consultar Conceptos"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
        {/* <div >
          <TableContainer
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable />
                </div>
              );
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default CONSUL_CONCEPT_V2;
