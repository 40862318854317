//PAQUETERIAS
import { useEffect, useState, useMemo, useRef} from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES

import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { SelectColumnFilter } from "../../components_cfdi/filters";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import "./almacenes.css";
import EditWarehouse from "./tools/op_warehouse";
import { delete_warehouse, get_warehouse } from "../../services/configuration/configuration";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import TableContainer from "../../main_components/Table/TableContainer";

const WAREHOUSES = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(()=>{
    handleSubmit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}

const handleSubmit = async () => {
    
    if(entrada===0){
      entrada=entrada+1;
      setmenssage("Buscando Almacenes...");
      setmodalGeneral(true);
      setmodalT(true); 
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);
    

    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_warehouse();
            setData(d === null ? [] : d.data.warehouses); 
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }              
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={() => {
              CustomDialog(               
             <div>
              <EditWarehouse operation={"CREATE"} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}/>
             </div>,
           {
             className: "modalTItle",
             title:"Crear Almacen",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Almacén"
            
            alt=""
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ valor, row }) => {
      const DeleteWH = async () => {  
      
      setmodalT(true); 
      setData([]);
      setopen(true);

        try {
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                const dt = await delete_warehouse(row.original.id);
                console.log(dt);
                alertas("Almacén Eliminado", true);
                handleSubmit();
              }catch(err){
                console.log("error", err);
                setopen(false);
                alertas("Error, no se pudo eliminar el almacén", false);
                setmodalT(false);
                handleSubmit();
              }         
            }, 1000);
          }else{}
        } catch (err) {
          console.log(err);
        }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de Eliminar el Almacén','Eliminar Almacén','Si','No');
                  if(result){
                    DeleteWH()
                    }
                  }            
                }
              
              title="Eliminar Almacén"
              alt=""
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={
                () => {
                  CustomDialog(               
                 <div>
                    <EditWarehouse operation={"EDIT"} row={row} handleSubmit={handleSubmit} reconsultar={reconsultar} alertas={alertas}></EditWarehouse>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Almacén",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              
              title="Editar Almacén"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Código",
      accessor:"code"
    },
    {
      Header:"Nombre",
      accessor:"warehouse_name"
    },
    {
      Header:"Descripción",
      accessor:"description"
    },
    {
      Header:"Tipo",
      accessor:"type",
      Filter: SelectColumnFilter
    },
    {
      Header:"Fecha registro",
      accessor:"date_register"
    }
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>
    <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR ALMACENES", path: null},
                ]
              }
      ></BREADCRUMBS>
<div className="TableAlmacenes">
  <div className="tablewarehousepp">      

    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>  
          <>
          <StickyTable>
            <TableContainer
              paginado={"Almacenes"}
              consultas={"Almacenes Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>    
          </div>
        </div>
    </>
  );
}

export default WAREHOUSES;
