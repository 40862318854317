//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog } from "react-st-modal";
import { StickyTable } from "react-sticky-table";

//COMPONENTES
import TableContainer from "../../../main_components/Table/TableContainer";
import { SelectColumnFilter, MultiSelectColumnFilter } from "../../filters";
import ReenvioCorreo from "./report_email/send_invoice";
import SubTable from "./detail/detail_invoce";
import CANCEL_INVOICE from "../cancel_invoice/cancel_invoice";
import {
  base64toBlob,
  base64toBlobxml,
} from "../../../main_components/donwloadfuncion/download";
import { get_invoices } from "../../../services/invoices/invoice";
import { download_invoice } from "../../../services/invoices/invoice";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_status } from "../../../services/invoices/invoice";
import SEARCH_FILTERS from "../../../main_components/Table/filter_shear";
import FormControlLabel from "@mui/material/FormControlLabel";
//ESTILOS
import "../../estilo.css";
import "../../modal.css";
import "../../table.css";

//IMAGENES
import tache from "../../../imgComponents/tache.png";
import tacheCANCEL from "../../../imgComponents/cancelado.png";
import PDF from "../../../imgComponents/pdf.png";
import PDFCANCEL from "../../../imgComponents/pdfcancel.png";
import XML from "../../../imgComponents/xml.png";
import XMLCANCEL from "../../../imgComponents/xmlcancel.png";
import EMAIL from "../../../imgComponents/correoElectronico.png";
import GENERAL_PUBLIC from "../../../imgComponents/icons/general_public.png";
import { COLUMS_SHOW } from "../../../main_components/Table/ColumsShow";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { MaterialUISwitch } from "../../../main_components/material_switch/switch";
import {
  formattedCurrentDate,
  formattedPreviousDate,
} from "../../../main_components/date/day";
import "./invoice.css";
import { MAP_RELATION_INVOICE } from "../map_relation/map_relation";
import maprel from "../../../imgComponents/iconos/mapa_relaciones.png";
import NotificationSystem from "react-notification-system";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
//COMPONENTES

const Vppd = ()=> {
  const [data, setData] = useState([]);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState(null);
  const [tipodoc, setTipoDoc] = useState("");
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [modalT, setmodalT] = useState(false);
  const [open, setOpen] = useState(false);
  const [reconsult, setreconsult] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(false);

  const notificationSystemRef = useRef();

   const [viewfecha, setviewfecha]=useState(
    localStorage.getItem("facturas_viewfechappd") === null
    ? true
    : localStorage.getItem("facturas_viewfechappd") === "true"
    ? true
    :false
  );
  const [viewserie, setviewserie]=useState(
    localStorage.getItem("facturas_viewserieppd") === null
    ? true
    : localStorage.getItem("facturas_viewserieppd") === "true"
    ? true
    :false
  );
  const [viewfolio, setviewfolio]=useState(
    localStorage.getItem("facturas_viewfolioppd") === null
    ? true
    : localStorage.getItem("facturas_viewfolioppd") === "true"
    ? true
    :false
  );
  const [viewtotal, setviewtotal]=useState(
    localStorage.getItem("facturas_viewtotalppd") === null
    ? true
    : localStorage.getItem("facturas_viewtotalppd") === "true"
    ? true
    :false
  );
  const [viewcliente, setviewcliente]=useState(
    localStorage.getItem("facturas_viewclienteppd") === null
    ? true
    : localStorage.getItem("facturas_viewclienteppd") === "true"
    ? true
    :false
  );
  const [viewrfc, setviewrfc]=useState(
    localStorage.getItem("facturas_viewrfcppd") === null
    ? true
    : localStorage.getItem("facturas_viewrfcppd") === "true"
    ? true
    :false
  );
  const [viewformapago, setviewformapago]=useState(
    localStorage.getItem("facturas_viewformapagoppd") === null
    ? true
    : localStorage.getItem("facturas_viewformapagoppd") === "true"
    ? true
    :false
  );
  const [viewmontopago, setviewmontopago]=useState(
    localStorage.getItem("facturas_viewmontopagoppd") === null
    ? true
    : localStorage.getItem("facturas_viewmontopagoppd") === "true"
    ? true
    :false
  );
  const [viewpagado, setviewpagado]=useState(
    localStorage.getItem("facturas_viewpagadoppd") === null
    ? true
    : localStorage.getItem("facturas_viewpagadoppd") === "true"
    ? true
    :false
  );
  const [viewmontodeuda, setviewmontodeuda]=useState(
    localStorage.getItem("facturas_viewmontodeudappd") === null
    ? true
    : localStorage.getItem("facturas_viewmontodeudappd") === "true"
    ? true
    :false
  );
  const [viewparcialidad, setviewparcialidad]=useState(
    localStorage.getItem("facturas_viewparcialidadppd") === null
    ? true
    : localStorage.getItem("facturas_viewparcialidadppd") === "true"
    ? true
    :false
  );
    const [viewestado, setviewestado]=useState(
    localStorage.getItem("facturas_viewestadoppd") === null
    ? true
    : localStorage.getItem("facturas_viewestadoppd") === "true"
    ? true
    :false
  );
  
  const [filtersColumsView, setfiltersColumsView] = useState(true);

  useEffect(()=>{
    localStorage.setItem("facturas_viewfechappd", viewfecha);
    localStorage.setItem("facturas_viewserieppd", viewserie);
    localStorage.setItem("facturas_viewfolioppd", viewfolio);
    localStorage.setItem("facturas_viewtotalppd", viewtotal);
    localStorage.setItem("facturas_viewclienteppd", viewcliente);
    localStorage.setItem("facturas_viewrfcppd", viewrfc);
    localStorage.setItem("facturas_viewformapagoppd", viewformapago);
    localStorage.setItem("facturas_viewmontopagoppd", viewmontopago);
    localStorage.setItem("facturas_viewpagadoppd", viewpagado);
    localStorage.setItem("facturas_viewmontodeudappd", viewmontodeuda);
    localStorage.setItem("facturas_viewparcialidadppd", viewparcialidad);
    localStorage.setItem("facturas_viewestadoppd", viewestado);
  },[
    viewfecha,
    viewserie,
    viewfolio,
    viewtotal,
    viewcliente,
    viewrfc,
    viewformapago,
    viewmontopago,
    viewpagado,
    viewmontodeuda,
    viewparcialidad,
    viewestado
  ])
   
 const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };








  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    setmodalGeneral(true);
    setData([]);
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            var dt = await get_invoices(fi, ff, rfc, sdn, tipodoc, cancel,"",true);
            setData(dt.data.slice().sort((a, b) => a.invoice_date < b.invoice_date));
            setmodalT(false); 
            setmodalGeneral(false);

          } catch (err) {
            setData([]);
            setmodalT(false);
            setmodalGeneral(false);

          } 
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => { 
    handleSubmit(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handle_status =async(id,uuid)=>{
    setmensajeDescarga("Verificando estado de la factura");
    setOpen(true);
    setreconsult(true)
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt= await get_status(id,uuid);
            sessionStorage.setItem( "faCancel",dt?.data?.cancellation_request_id);
            // alert("Estado actualizado");
            setOpen(false);
            setmensajeDescarga("");
            setreconsult(false)
      
          } catch (err) {
            alert(JSON.stringify(err.response.data));
            setreconsult(false)
      
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span>{isAllRowsExpanded ? "🔼" : "🔽"}</span>
        ),
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("dataSuntablefiltro", row.original.uuid_sat);
            sessionStorage.setItem("dataSuntablefiltroID", row.original.id);
            console.log(row.original.folio);
          }

          return (
            <a {...row.getToggleRowExpandedProps()}>
              {row?.isExpanded ? (
                <span>🔼</span>
              ) : (
                <span onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
        maxWidth: 50,
        width: 50,
        className: "justify_data",
      },
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          
          async function download(typedocumentexmlorpdf,id_invoce,fileName_document) {
            setmensajeDescarga("Descargando");
            setOpen(true);
            try {
              const data = await download_invoice(
                typedocumentexmlorpdf,
                fileName_document,
                id_invoce
              );
              var dt = data.data.file;
              var blob = base64toBlob(dt);
              const blobUrl = URL.createObjectURL(blob);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              let arr = row.original.filename.split("/");
              downloadLink.download = arr[4] + `.${typedocumentexmlorpdf}`;
              downloadLink.click();
              setmensajeDescarga("");
              setOpen(false);
            } catch (err) {
              setOpen(false);
            }
          }

          return (
            <div>
              <div className="contanerActionImg">
                <div className="fat-1">
                  <img
                  alt=""
                    style={{display: row.original.cancel === false ? "none" : ""}}
                    src={tacheCANCEL}
                    title="REVERIFICAR ESTADO DE LA FACTURA"
                    onClick={() => handle_status(row.original.id,row.original.uuid_sat)}
                  ></img>

                  <img
                  alt=""
                    src={tache}
                    style={{display: row.original.cancel === false ? "" : "none"}}
                    className="cancelIMG"
                    title="Cancelar Factura"
                    onClick={async () => {
                      if (
                        sessionStorage.getItem("PERMISOCANCEL") ===
                        "ROLE_CANCEL_INVOICE"
                      ) {
                        sessionStorage.setItem("rfcBusqueda",row.original.rfc_business_partner);
                        sessionStorage.setItem("rowUuid",row.original.uuid_sat);
                        sessionStorage.setItem("id_cancelF", row.original.id);
                        handle_status(row.original.id,row.original.uuid_sat);
                        
                        await CustomDialog(
                          <CANCEL_INVOICE
                            idCancel={row.original.id}
                          ></CANCEL_INVOICE>,
                          {
                            className: "modalCancelR",
                            title:"Seleccione el motivo por el cual desea cancelar la factura",
                            showCloseIcon: true,
                          }
                        );
                      } else {
                        alert("No tienes permisos para cancelar");
                      }
                    }}
                  ></img>
                </div>
                <div className="fat-2">
                  <img
                  alt=""
                    src={XML}
                    title="Descargar XML"
                    className="cancelIMG2"
                    onClick={() =>
                      download("xml", row.original.id, row.original.filename)
                    }
                  ></img>
                </div>

                <div className="fat-3">
                  <img
                  alt=""
                    src={PDF}
                    title="Descargar PDF"
                    className="cancelIMG2"
                    onClick={() =>
                      download("pdf", row.original.id, row.original.filename)
                    }
                  ></img>
                </div>

                <div className="fat-5">
                  <img
                    alt=""
                    src={XMLCANCEL}
                    style={{
                      display: row.original.cancel === false ? "none" : "",
                    }}
                    onClick={() =>
                      download(
                        "xml",
                        row.original.id,
                        row.original.filename_cancel
                      )
                    }
                    title="Descargar XML Cancel"
                  ></img>
                </div>

                <div className="fat-6">
                  <img
                    alt=""
                    src={PDFCANCEL}
                    style={{
                      display: row.original.cancel === false ? "none" : "",
                    }}
                    onClick={() =>
                      download(
                        "pdf",
                        row.original.id,
                        row.original.filename_cancel
                      )
                    }
                    title="Descargar PDF Cancel"
                  ></img>
                </div>
                <div className="fat-4">
                  <img
                    alt=""
                    src={EMAIL}
                    className="cancelIMG2"
                    title="ENVIAR CORREO"
                    onClick={async () => {
                      sessionStorage.setItem(
                        "FACTURAMANDAR",
                        row.original.uuid_sat
                      );

                       await CustomDialog(
                        <ReenvioCorreo
                          report={false}
                          id={row.original.id}
                          uuid={row.original.uuid_sat}
                        ></ReenvioCorreo>,
                        {
                          className: "modalTItle",
                          title:
                            'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO',
                          showCloseIcon: true,
                        }
                      );
                    }}
                  ></img>
                </div>
              </div>
            </div>
          );
        },
        maxWidth: 100,
        width: 100,
        className: "justify_data",
      },

      {
        Header: "Fecha",
        accessor: "invoice_date",
        show: viewfecha,
        id: "invoice_data",
        maxWidth: 100,
        width: 100,
        className: "justify_data",
      },
      {
        Header: "Serie",
        accessor: "serie",
        id: "seriee",
        show: viewserie,
        maxWidth: 70,
        width: 70,
        className: "justify_data",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "cancel",
        Header: "Folio",
        accessor: "folio",
        show: viewfolio
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "total",
        show: viewtotal,
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },
      {
        maxWidth: 130,
        minWidth: 130,
        width: 130,
        Header: "Cliente",
        accessor: "name_business_partner",
        show: viewcliente,
        id: "name_business_partner"
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "rfc_business_partner",
        show: viewrfc,
        Header: "RFC",
        accessor: "rfc_business_partner",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id:"forma_pago",
        show: viewformapago,
        Header: "Forma de pago",
        accessor: "forma_pago",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "amount_paid",
        show: viewmontopago,
        Header: "Monto de pago",
        accessor: (d) => {
          return d.amount_paid
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_paid)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_paid);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "paid",
        show: viewpagado,
        Header: "Pagado",
        accessor: (d) => {
          return d.paid === true ? "SI" : "NO";
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "amount_of_debt",
        show: viewmontodeuda,
        Header: "Monto de deuda",
        accessor: (d) => {
          return d.amount_of_debt
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_of_debt)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_of_debt);
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "installment",
        show: viewparcialidad,
        Header: "Parcialidad",
        accessor: "installment",
      },
      {
        Filter: SelectColumnFilter,
        filter: "equals",
        Header: "Estado",
        accessor: (d) => {
          return d.cancel === true ? "CANCELADO" : "VIGENTE";
        },
        show: viewestado,
        id: "estado"
      },
      {
        Header: "Usuario",
        accessor: "username",
        id: "username",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className:"justify_data"
      },
    ],
    [
    viewfecha,
    viewserie,
    viewfolio,
    viewtotal,
    viewcliente,
    viewrfc,
    viewformapago,
    viewmontopago,
    viewpagado,
    viewmontodeuda,
    viewparcialidad,
    viewestado
    ]
  );


  const datacolum =[
    {
      view: viewfecha,
      setview: setviewfecha,
      label: "Fecha"
    },
    {
      view: viewserie,
      setview: setviewserie,
      label:"Serie"
    },
    {
      view: viewfolio,
      setview: setviewfolio,
      label:"Folio"
    },
    {
      view: viewtotal,
      setview: setviewtotal,
      label:"Total"
    },
    {
      view: viewcliente,
      setview: setviewcliente,
      label:"Cliente"
    },
    {
      view: viewrfc,
      setview: setviewrfc,
      label:"RFC"
    },
    {
      view: viewformapago,
      setview: setviewformapago,
      label:"Forma de Pago"
    },
    {
      view: viewmontopago,
      setview: setviewmontopago,
      label:"Monto de Pago"
    },
    {
      view: viewpagado,
      setview: setviewpagado,
      label:"Pagado"
    },
    {
      view: viewmontodeuda,
      setview: setviewmontodeuda,
      label: "Monto de Deuda"
    },
    {
      view: viewparcialidad,
      setviewparcialidad,
      label: "Parcialidad"
    },
    {
      view: viewestado,
      setview: setviewestado,
      label:"Estado"
    }
  ];

  const search_filters = [
    {
      slot: (
        <input
          type="text"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha inicial"
          onChange={(event) => setfi(event.target.value)}
        ></input>
      ),
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: (
        <input
          type="text"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Final"
          onChange={(event) => setff(event.target.value)}
        ></input>
      ),
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: (
        <select
          id="canceladas-si-no"
          onChange={(event) => setCancel(event.target.value)}
        >
          <option>Estado de la factura</option>
          <option value={true}>CANCELADO</option>
          <option value={false}>VIGENTE</option>
          <option value={""}>TODOS</option>
        </select>
      ),
      fr: null,
      padding: 5,
    },
    {
      slot: (
        <input
          type="text"
          placeholder="Busqueda por RFC"
          className="filtroRFC"
          onChange={(event) => setRFC(event.target.value)}
        ></input>
      ),
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: (
        <input
          type="text"
          placeholder="Busqueda por socio de negocios"
          onChange={(event) => setSDN(event.target.value)}
        ></input>
      ),
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: (
        <select onChange={(event) => setTipoDoc(event.target.value)}>
          <option>Tipo de documento</option>
          <option value={"FACTURA"}>FACTURA</option>
          <option value={"NOTA DE CREDITO"}>NOTA DE CRÉDITO</option>
          <option value={"TRASLADO"}>CARTA PORTE</option>
          <option value={"PAGO"}>PAGO</option>
          <option value={"null"}>TODOS</option>
        </select>
      ),
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: (
        <button
          style={{ width: "100%" }}
          className="button"
          onClick={handleSubmit}
          type="button"
        >
          Buscar
        </button>
      ),
      fr: null,
      display: null,
      padding: null,
    },
    {
      slot: (
        <FormControlLabel
          control={
            <MaterialUISwitch
              sx={{ m: 1 }}
              onChange={handlechecked}
              checked={filtersColumsView}
            />
          }
          label={
            filtersColumsView === true
              ? "Activar Filtros"
              : "Desactivar Filtros"
          }
        />
      ),
      fr: null,
      padding: null,
      display: null,
    },

    
  ];
 
  return (
    <>
    <BREADCRUMBS
        niveles={[{ label: "CONSULTAR FACTURAS PPD", path: null }]}
        new_version={true}
        modul="CONSULTAR FACTURAS PPD V2"
        path={"/FacturasPPD_v2"}
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={"Consultar Facturas PPD"}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <SEARCH_FILTERS search_filters={search_filters}></SEARCH_FILTERS>
      <COLUMS_SHOW
        filtersColumsView={filtersColumsView}
        datacolums={datacolum}
      />
      <div>
        <div className="TableConsultarTodo">
          <StickyTable>
            <TableContainer
              nametable={`Facturas del ${fi} al ${ff}`}
              zipfacturas={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              consultas={"Facturas"}
              exportar={true}
              Grantotal={true}
              hpdf={2}
              
              GrantotalCabeceras={[
                "Fecha",
                "Ticket",
                "Punto de Venta",
                "Serie",
                "Folio",
                "Cliente",
                "Serie/Folio",
                "RFC",
                "Metodo de pago",
                "Tipo de comprobante",
                "Estado",
                "Usuario",
                "Estado en SAT",
                "Nota",
                "UUID_SAT",
                "¿Es cancelable?",
              ]}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }} >
                    <SubTable
                      reconsult={reconsult}
                      data={data}
                      tipo={row}
                      columns={columns}
                      defaultPageSize={3}
                      showPagination={false}
                    />
                  </div>
                );
              }}
            />
          </StickyTable>
        </div>
      </div>
    </>
    
  );
};

export default Vppd;
