import axios from "axios";
import config from "../../config";

const headersdata = {
  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
  "Content-Type": "application/json",
};

const urlbp = `${config?.baseURLBP}`;
const urlpvd =`${config?.baseURLPVD}`;
const urlsp =`${config?.baseURLSP}`;



//Actualizar las relaciones entre un sector y los puntos de venta mediante archivo
export const post_array_pos_sector = async (id, data) => {
  const url = `${urlbp}/development/sectors/${id}/pos/update-from-file`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Eliminar socio de negocios
export const delete_business_partner = async (id) => {
  const url = `${urlbp}/dev/businesspartners/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Eliminar socio de negocios
export const get_supplier = async (id) => {
  const url = `${urlsp}/development/businesspartners`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Actualizar socio de negocios
export const put_businesspartners = async () => {
  const url = `urlbp/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar dirección
export const get_address = async (id) => {
  const url = `${urlbp}/dev/businesspartners/${id}/address`;
  try {
    const response = await axios.get(url, {
      params: {
        role: "Consulta",
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar Código Socio de Negocios
export const get_code_businesspartners = async () => {
  const url = `${urlbp}/dev/businesspartners/code`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//Crear socio
export const create_bp = async (dt) => {
  const url = `${urlbp}/dev/businesspartners`;
  try {
    const response = await axios.post(url,dt,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Actualizar socio de negocios
export const update_bp = async (dt,id) => {
  const url = `${urlbp}/dev/businesspartners/${id}`;
  try {
    const response = await axios.put(url,dt,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Consultar dirección de un socio o proveedor
export const get_address_businesspartners = async (id) => {
  const url = `${urlbp}/dev/businesspartners/${id}/address`;
  try {
    const response = await axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar datos generales de un socio o proveedor
export const get_data_businesspartners = async (id) => {
  const url = `${urlbp}/dev/businesspartners/${id}`;
  try {
    const response = await axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_socioHabilitado = async (habilitado) => {
  const url = `${urlbp}/dev/businesspartners/${habilitado}`;
  try {
    const response = await axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_pointsale_cabinetbs = async (cabinetsBs) => {
  const url = `${urlbp}/dev/pointsofsale/${cabinetsBs}/cabinets`;
  try {
    const response = await axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_pointsale_sectors = async (cabinetsBs) => {
  const url = `${urlbp}/dev/pointsofsale/${cabinetsBs}/sectors`;
  try {
    const response = await axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_businesspartners_rfc = async (bp, rfc) => {
  const url = `${urlbp}/dev/pointsofsale/business-partner?partner=${bp}&rfc=${rfc}`;
  try {
    const response = await axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_businesspartners = async (id) => {
  const url = `${urlbp}/dv/businesspartners/${id}}`;
  try {
    const response = await axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



//Consultar Socio de Negocios
export const get_set_businesspartners = async () => {
  const url = `${urlbp}/dev/businesspartners`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar Proveedores

export const get_businesspartners_supplier = async () => {
  const url = `https://h93zxd4wrb.execute-api.us-east-1.amazonaws.com/od/businesspartners`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


////////////////////////////////MODULO DESCUENTOS ///////////////////////////////////////////

//consultar todos los descuentos
export const get_discounts = async () => {
  const url = `${urlbp}/dev/discounts`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//Eliminar descuento
export const detele_discount = async (id) => {
  const url = `${urlbp}/dev/discounts/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Crear descuento
export const post_discount = async (data) => {
  const url = `${urlbp}/dev/discounts`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const put_discount = async (data, id) => {
  const url = `${urlbp}/dev/discounts/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



/////////////////////////////////SECTORES ////////////////////////////////////////

//consultar sectores
export const get_sectors = async () => {
  const url = `${urlbp}/dev/sectors`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//ELIMINAR SECTOR
export const detele_sector = async (id) => {
  const url = `${urlbp}/dev/sectors/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Crear sector
export const post_sector = async (data) => {
  const url = `${urlbp}/dev/sectors`;
  try {
    const response = await axios.post(url, data, {
      headers: headersdata,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//actualizar sector
export const put_sector = async (data, id) => {
  const url = `${urlbp}/dev/sectors/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

/////////////////PUNTOS DE VENTA POR SECTOR /////////////////////////////

//consultar todos los puntos de venta relacionados a un sector
export const get_pos_sector = async () => {
  const url = `${urlbp}/dev/sectors/${sessionStorage.getItem(
    "id_sector"
  )}/pos`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//eliminar punto de venta relacionado a sector
export const delete_pos_sector = async (id) => {
  const url = `${urlbp}/dev/sectors/${sessionStorage.getItem("id_sector")}/pos/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//agregar punto de venta al sector
export const post_pos_sector = async (id, data) => {
  const url = `${urlbp}/dev/sectors/${id}/pos`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

///////////////////PROMOCIONES ///////////////////////////

//CONSULTAR PROMOCIONES
export const get_promotions = async () => {
  const url = `${urlbp}/dev/promotions`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//consultar detalles de una promcoion
export const get_promotions_details = async (id) => {
  const url = `${urlbp}/dev/promotions/${id}/details`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//ELIMINAR PROMOCION
export const delete_promotion = async (id) => {
  const url = `${urlbp}/dev/promotions/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//eliminar detalle de una promocion        
export const delete_promotion_details = async (id_promotions_fk, id) => {
  const url = `${urlbp}/dev/promotions/${id_promotions_fk}/details/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//crear promocion
export const post_promotion = async (data) => {
  const url = `${urlbp}/dev/promotions`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//AGREGAR DETALLES A LA PROMOCION
export const post_promotion_details = async (id, data) => {
  const url = `${urlbp}/dev/promotions/${id}/details`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//editar detalles de promociones
export const put_details_promotions = async (id_promotios_fk, id, data) => {
  const url = `${urlbp}/dev/promotions/${id_promotios_fk}/details/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//editar promocion
export const put_promotions = async (id, data) => {
  const url = `${urlbp}/dev/promotions/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//consultar productos para promociones
export const get_products = async () => {
  const url = `${urlbp}/dev/products`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//////////////////////PUNTOS DE VENTA//////////////////////////////
export const post_points_of_sale = async (data) => {
  const url = `${urlbp}/dev/pointsofsale`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const put_points_of_sale = async (id, data) => {
  const url = `${urlbp}/dev/pointsofsale/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

  export const delete_points_of_sale = async (id) => {
  const url = `${urlbp}/dev/pointsofsale/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//consultar direccion de un punto de venta
export const get_pointsofsale_address = async (id) => {
  const url = `${urlbp}/dev/pointsofsale/${id}/address`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

         
//consultar congelador del punto de venta 
export const get_pointsofsale_cabinets = async (id) => {
  const url = `${urlbp}/dev/pointsofsale/${id}/cabinets`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
    
//consultar congelador del punto de venta 
export const get_pointsofsale_sectors = async (id) => {
  const url = `${urlbp}/dev/pointsofsale/${id}/sectors`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

////////////REGISTRO GENERAL //////////////////////
export const post_register_general = async (data) => {
  const url = `${urlbp}/dev/generalregistration`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

////////////////PUNTOS DE VENTA SIN SECTOR///////////////////

//consultar todos los puntos de veNta sin sector
export const get_pos_without_sector = async () => {
  const url = `${urlbp}/dev/poswhitoutsector`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

////////////////////RECUPERAR SOCIO DE NOGOCIOS Y PUNTO DE VENTA//////////////

//consulta para recuperar socio de negocios
export const get_pos_recover = async () => {
  const url = `${urlbp}/dev/recover`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//RECUPERAR SOCIO Y PUNTO DE VENTA
export const  post_recover= async (data) => {
  const url = `${urlbp}/dev/recover`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



////////////////////SUBCONSULTAS///////////////////////////////////

//consultar listado de socios de negocios
export const get_businesspartners_list = async () => {
  const url = `${urlbp}/dev/businesspartners/namelist`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//consultar listado de puntos de venta
export const get_pointsofsale_list = async () => {
  const url = `${urlbp}/dev/pointsofsale/namelist`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//consultar linea de productos
export const get_productslines = async () => {
  const url = `${urlbp}/dev/productslines`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//consultar listado de rutas
export const get_route_list = async () => {
  const url = `${urlbp}/dev/routes/namelist`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//consultar clasificaciones puntos de venta
export const get_clasification = async () => {
  const url = `${urlbp}/dev/pointsofsale/classifications`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//consultar todos los requerimentos registro general
export const get_requirements = async () => {
  const url = `${urlbp}/dev/pointsofsale/requirements`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
