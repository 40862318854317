import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import Spinner from "../../../imgComponents/S.gif";
import tax_sat from "../../../dataComponets/taxes_sat.json";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { post_taxes } from "../../../services/invoices/invoice";

const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const traslado = [
  { value: "true", label: "Si" },
  { value: "false", label: "No" },
];

const retencion = [
  { value: "true", label: "Si" },
  { value: "false", label: "No" },
];

function Set_tax(props) {
  const [TypeImpuesto, setTypeImpuesto] = React.useState("003");
  const [TypeImpuestoLabel, setTypeImpuestoLabel] = React.useState("IEPS");
  const [TypeTraslado, setTypeTraslado] = React.useState("false");
  const [TypeRestriccion, setTypeRestriccion] = React.useState("false");
  const [open, setOpen] = useState(false);

  const handelTaxes = (event) => { setTypeImpuesto(event?.target?.value);
    console.log(event?.target?.value);
    if(event?.target?.value==="001"){setTypeImpuestoLabel("ISR");setTypeImpuesto("001");}
    else if(event?.target?.value==="002"){setTypeImpuestoLabel("IVA");setTypeImpuesto("002");}
    else if(event?.target?.value==="003"){setTypeImpuestoLabel("IEPS");setTypeImpuesto("003");}
    else if(event?.target?.value==="ISH"){setTypeImpuestoLabel("ISH");setTypeImpuesto("004");}
  };
  const handelTransfer = (event) => { setTypeTraslado(event?.target?.value); };
  const handelRestrictions = (event) => { setTypeRestriccion(event?.target?.value); };
  
  const dialog = useDialog();
  

  useEffect(() => {
    handelTaxes();
  }, []);

  useEffect(() => {
    handelTransfer();
  }, []);
  useEffect(() => {
    handelRestrictions();
  }, []);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    console.log(TypeImpuesto);
    var data={ 
      tax_name: TypeImpuestoLabel, 
      retention: TypeRestriccion==="true"?true:false, 
      transfer: TypeTraslado==="true"?true:false, 
      c_impuesto: (TypeImpuesto===undefined)?"003":TypeImpuesto  
    };
    
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await post_taxes(data);
            props?.new ? props?.alertas("Éxito, impuesto generado correctamente", true):alert('Éxito, impuesto generado correctamente'); 
            setOpen(false);
            dialog.close();
            props?.new ? props?.handleSubmit(): window.location="/consult_taxes"; 
          }catch(err){
            catchErrorModul("Impuestos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            setOpen(false); 
            alert("Error.,"+ err.response.data.errorMessage);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  return (
    <div>
      <div>
           <Modal
             open={open}
             onClose={open}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
             >
               <Box sx={style2} >
                 <div className="centermodaldescarga">
                   <Typography id="modal-modal-title"    variant="h6" component="h2">
                     <img src={Spinner}></img>
                   </Typography>
                   <Typography id="modal-modal-description"  sx={{ mt: 2 }}>  
                     {"Creando Impuesto..."}
                   </Typography>
                   </div>
                 </Box>
            </Modal>
       </div>
      <div>
        <form>
          <Box>
            <TextField
              select
              label="Impuesto"
              value={TypeImpuesto}
              sx={{ m: 1.5, width: "20ch" }}
              onChange={handelTaxes}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {tax_sat.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.tax}
                >
                  {option.tax}
                </option>
              ))}
            </TextField>
            
            <TextField
              select
              label="Traslado"
              value={TypeTraslado}
              sx={{ m: 1.5, width: "20ch" }}
              onChange={handelTransfer}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {traslado.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>

            <TextField
              select
              label="Retenciones"
              value={TypeRestriccion}
              sx={{ m: 1.5, width: "20ch" }}
              onChange={handelRestrictions}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {retencion.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.label}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>

          <br></br>

          <Box>
            <Button
              variant="contained"
              type="submit"
              className="ButtonModal"
              sx={{ m: 1.5, width: "30%" }}
              onClick={handleSubmit}
            >
              Crear
            </Button>
            <Button
              variant="contained"
              className="ButtonModal2"
              sx={{ m: 1.5, width: "30%" }}
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default Set_tax;
