//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable} from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import CreateDiscount from "./CreateDiscount";
import EditDiscount from "./EditDiscount";
import TableContainer from "../../main_components/Table/TableContainer";
import { detele_discount, get_discounts } from "../../services/businesspartners/businesspartners";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../componentSociosNegocios/componentSectors/estilo.css";
import "../../components_cfdi/modal.css";
import "./discount.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

const TableDiscount = () => {
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  const notificationSystemRef = useRef();
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

useEffect(()=>{
  handleSubmit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}

//proceso de consultar todos los descuentos
const handleSubmit = async () => {
    
  console.log("entra a consultar todos los descuentos");
  
  if(entrada===0){
    setmenssage("Buscando Descuentos...");
    setmodalGeneral(true);
    setmodalT(true); 
    entrada=entrada+1;
  }else{
    setmodalT(true);
  }
  setData([]);
  setopen(true);
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => { 
          try{
            var prueba = await get_discounts();
            setData(prueba === null ? [] : prueba.data.discounts);   
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    } catch (error) {
      console.log(error);
    }
};

//Funcion de alertas personalizadas
function alertas(msj,status){
  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}


const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearsector"
            src={CREATE}
            onClick={() => {
               CustomDialog(               
             <div>
                <CreateDiscount reconsultar={reconsultar} handleSubmit={handleSubmit} alertas={alertas}></CreateDiscount>
             </div>,
           {
             className: "modalTItle",
             title:"Crear nuevo Descuento",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Descuento"
            alt=""
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ row }) => {
      const DeleteSector = async ()=>{  
      
      setmodalT(true);
      setData([]);
      setopen(true);
        try {
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                const dt = await detele_discount(row.original.idDiscount);
                console.log(dt);
                alertas("Descuento eliminado con éxito", true);         
                handleSubmit();
              }catch(err){
                setopen(false);
                setmodalT(false);
                alertas("Error, no se pudo eliminar el descuento");
                handleSubmit();
              }
            }, 1000);
          }else{}    
        } catch (err) {
         console.log(err);
        }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el descuento?','Eliminar Descuento','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              
              title="Eliminar Descuento"
              alt=""
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={
                async () => {
                  CustomDialog(               
                 <div>
                    <EditDiscount reconsultar={reconsultar} handleSubmit={handleSubmit} alertas={alertas} row={row}></EditDiscount>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Descuento",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              
              title="Editar Descuento"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Filter: SelectColumnFilter, 
      Header:"Tipo de Descuento",
      accessor: (d)=>{
       return d.namePointSale==null?"Socio de Negocios":"Punto de Venta"
      }
    },
    {
      Header: "Código Punto Venta",
      accessor: "codePointSale",
    },
    {
      Header: "Punto de Venta",
      accessor: (d)=>{
        return d.namePointSale==null?"NO APLICA":d.namePointSale  
      }
    },
    {
      Header: "Socio de Negocios",
      accessor: (d)=>{
        return d.business_partner==null?"NO APLICA":d.business_partner
      }
    },
    {
      Header: "Etiqueta",
      accessor: "tag",
    },
    {
      Header:"Descuento %",
      accessor: "discount"
    },
    {
      Header: "Orden",
      accessor: "discount_order"
    },
    {
      Filter: SelectColumnFilter,
      Header: "Disponible",
      accessor: (d) => {
        return d.enable === true?"Disponible":"No Disponible"
      }
    },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>


<div className="TableDiscount">
  <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
  ></MODAL_TABLE>
          <BREADCRUMBS niveles={
            [
              {label:"CONSULTAR DESCUENTOS", path: null}, 
            ]
          }
          ></BREADCRUMBS>
        <div className="tabledesc">  
          <StickyTable>
            <TableContainer
              paginado={"Descuentos"}
              consultas={"Descuentos Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
              nametable={"Tabla Descuentos"}
            />
          </StickyTable>
          </div>
        </div>
    </>
  );
}

export default TableDiscount;
