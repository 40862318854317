import TableConfigureTaxes from '../../components_cfdi/invoice/componentsTaxes/Consult_Configure_Tax';
import { AuthState } from "@aws-amplify/ui-components";
import React, { Component } from "react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";


export class SetConsulConfigureTaxes extends Component {
  constructor(props) {
    super(props);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.state = {
      open: false,
      minutes: 20,
      seconds: 0,
      x: 0,
      y: 0,
    };
  }
  
  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }

      var tiempofial = minutes + ":" + seconds;
      if (tiempofial === "0:0") {
        this.props.SetAuthState(AuthState.SignedOut);
        alert("Sesión terminada por inactividad");
      }
    }, 1000);
  }

  handleMouseMove(event) {
    this.setState({
      x: event.clientX,
      y: event.clientY,
      minutes: 20,
      seconds: 0,
    });
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {

    const poolData = {UserPoolId: "us-east-1_Jcy1tYt0p", ClientId: "3m62suo1pe183guj8e8diae0kj"};
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {cognitoUser.getSession((err, session) => {if (err) {} else if (!session.isValid()) {} else {}});} else {}
      const idTokenRefresh=cognitoUser?.signInUserSession?.idToken.jwtToken;
      const accesTokenRefresh=cognitoUser?.signInUserSession?.accessToken.jwtToken;
      const tokenRefresh =  cognitoUser?.signInUserSession?.refreshToken?.token;
      var tiempoexp=cognitoUser?.signInUserSession?.idToken?.payload.exp;
      var tiempoexp2=cognitoUser?.signInUserSession?.accessToken?.payload.exp;

      const expires = new Date(tiempoexp * 1000);
      const timeout = expires.getTime() - Date.now();
      const expires2 = new Date(tiempoexp2 * 1000);
      const timeout2 = expires2.getTime() - Date.now();

      if(timeout<120){
        cognitoUser?.refreshSession(tokenRefresh, function (err, session) {
          sessionStorage.setItem("getIdTokenRefresh",session?.getIdToken()?.getJwtToken());
      }); 
      }else{
        sessionStorage.setItem("getIdTokenRefresh",idTokenRefresh);
      }
      
      if(timeout2<120){
        cognitoUser?.refreshSession(tokenRefresh, function (err, session) {
          sessionStorage.setItem("getAccesTokenRefresh",session?.getAccessToken()?.getJwtToken());
      }); 
      }else{
        sessionStorage.setItem("getAccesTokenRefresh",accesTokenRefresh);

      }

      return (    
      
      <div className="tc" onClick={this.handleMouseMove}>
      <TableConfigureTaxes></TableConfigureTaxes>

      </div>
          );
       }     
}

export default SetConsulConfigureTaxes;
