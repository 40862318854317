import axios from "axios";
import config from "../../config";

const urlinc = `${config?.baseURLIVC}`;
const urlhr = `${config?.baseURLHR}`;
const urlsat = `${config?.baseURLSAT}`;



//Consultar Facturas
export const get_invoices = async (fi, ff, rfc, sdn, tipodoc, cancel,uuid,debt) => {
  const url = `${urlinc}/od/invoices`;
  try {
    const response = await axios.get(url, {
      params: {
        di: fi,
        df: ff,
        rfc: rfc|| null,
        b_partner: sdn || null,
        type: tipodoc || null,
        cancel: cancel || null,
        uuid: uuid,
        debt:debt

      },
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar detalles de Facturas
export const get_invoices_detail = async (id, uuid) => {
  const url = `${urlinc}/od/invoices/${id}`;
  try {
    const response = await axios.get(url, {
      params: {
        uuid: uuid,
      },
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_invoices_detail_P = async (id) => {
  const url = `${urlinc}/od/invoices/${id}`;
  try {
    const response = await axios.get(url, {
      params: {
        c_tipodecomprobante: "P",
      },
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//mapa de relaciones

export const get_invoices_map = async (id) => {
  const url = `${urlinc}/od/invoices/${id}/related`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_invoices_payment_report = async (fi, ff) => {
  const url = `${urlinc}/od/invoices/payment/report`;
  try {
    const response = await axios.get(url, {
      params: {
        di: fi, 
        df: ff
      },
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_status_merhod_version_2 = async (id) => {
  const url = `${urlinc}/test/invoices/${id}/cancel/sat`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Descargar facturas
export const download_invoice = async (
  typedocumentexmlorpdf,
  fileName_document,
  id
) => {
  const url = `${urlinc}/od/invoices/${id}/download`;
  try {
    const response = await axios.get(url, {
      params: {
        filename: fileName_document,
        documentType: typedocumentexmlorpdf,
      },
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Enviar facturas
export const send_invoice = async (id,uuid,email) => {
  const url = `${urlinc}/od/invoices/${id}/send_email`;
  try {
    const response = await axios.post(url, {
      uuid: uuid,
      email: email,
    }, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Generar reporte publico en general
export const general_public = async (fi,ff,dta) => {
  const url = `${urlinc}/od/invoices/general_public/report?di=${fi}&df=${ff}`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Generar reporte publico en general
export const general_commissions_reports = async (fi,ff,dta) => {
  const url = `${urlhr}/od/commissions/reports?date_start=${fi}&date_end=${ff}`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};




//Seguimineto de facturas canceladas
export const get_status = async (id,uuid) => {
  const url = `${urlinc}/od/invoices/${id}/cancel`;
  try {
    const response = await axios.get(url, {
      params: {
        uuid: uuid,
      },
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Cancelar factura
export const cancel_invoice = async (id,dta) => {
  const url = `${urlinc}/od/invoices/${id}/cancel`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Consultar series
export const get_series = async (type) => {
  const url = `${urlinc}/od/series`;
  try {
    const response = await axios.get(url, {
      params: {
        type: type,
      },
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Calcular impuestos al concepto
export const calculate_tax = async (dta) => {
  const url = `${urlinc}/od/concepts/calculate`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Genrar Factura
export const generate_invoice = async (dta) => {
  const url = `${urlinc}/od/invoices`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar conceptos para facturar
export const get_concept = async () => {
  const url = `${urlinc}/od/concepts`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Consultar todos los conceptos 
export const get_concept_config = async () => {
  const url = `${urlinc}/od/concepts?filtro=All`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar catalogo sat unidades 
export const get_units = async () => {
  const url = `${urlinc}/od/sat_catalogue/units`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar catalogo sat productos y servicios 
export const get_prod_serv = async () => {
  const url = `${urlinc}/od/sat_catalogue/prod_serv`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar pagos
export const get_payment = async (fi, ff, rfc, sdn, tipodoc, cancel,uuid,debt) => {
  const url = `${urlinc}/od/invoices/payment`;
  try {
    const response = await axios.get(url, {
      params: {
        di: fi,
        df: ff,
        bp: rfc,
        b_partner: sdn || null,
        type: tipodoc || null,
        cancel: cancel || null,
        uuid: uuid,
        debt:debt

      },
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Enviar facturas
export const generate_payment = async (dt) => {
  const url = `${urlinc}/od/invoices/payment`;
  try {
    const response = await axios.post(url,dt,{
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Calculate concept
export const calculate_concept = async (dt) => {
  const url = `${urlinc}/od/concepts/calculate`;
  try {
    const response = await axios.post(url,dt,{
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar conductores
export const get_drivers = async (dt) => {
  const url = `${urlinc}/od/drivers`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar vehiculos
export const get_vehicles = async (dt) => {
  const url = `${urlinc}/od/vehicles`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Consultar conceptos para carta porte
export const get_concept_ctp = async () => {
  const url = `${urlinc}/od/concepts?filtro=CARTA PORTE`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//consultando configuracion impuestos
export const get_taxes_configs = async () => {
  const url = `${urlinc}/od/taxes/configs?`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const put_taxes_configs = async (id, data) => {
  const url = `${urlinc}/od/taxes/10/config/${id}`;
  try {
    const response = await axios.put(url,data, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_taxes = async () => {
  const url = `${urlinc}/od/taxes?`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const delete_taxes = async (id) => {
  const url = `${urlinc}/od/taxes/${id}`;
  try {
    const response = await axios.delete(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_taxes_sub_config = async (id_tax_subtable) => {
  const url = `${urlinc}/od/taxes/${id_tax_subtable}/config`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const delete_taxes_sub_config = async (id_tax_subtable, id) => {
  const url = `${urlinc}/od/taxes/${id_tax_subtable}/config/${id}`;
  try {
    const response = await axios.delete(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post_taxes_config = async (id_taxes, data) => {
  const url = `${urlinc}/od/taxes/${id_taxes}/config`;
  try {
    const response = await axios.post(url, data, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post_taxes = async (data) => {
  const url = `${urlinc}/od/taxes`;
  try {
    const response = await axios.post(url, data, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

///////////////////////////   conceptos /////////////////////////////////////////////////////
export const delete_concepts = async (id) => {
  const url = `${urlinc}/od/concepts/${id}`;
  try {
    const response = await axios.delete(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post_concepts = async (data) => {
  const url = `${urlinc}/od/concepts`;
  try {
    const response = await axios.post(url,data, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const get_concepts_taxes = async (id_concept_subtable) => {
  const url = `${urlinc}/od/concepts/${id_concept_subtable}/taxes`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const delete_concepts_taxes = async (id_concept_subtable, id) => {
  const url = `${urlinc}/od/concepts/${id_concept_subtable}/taxes/${id}`;
  try {
    const response = await axios.delete(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const put_concepts_taxes = async (id_concept, data) => {
  const url = `${urlinc}/od/concepts/${id_concept}`;
  try {
    const response = await axios.put(url, data, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_sat_catalogue_units = async () => {
  const url = `${urlinc}/od/sat_catalogue/units`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_sat_catalogue_prod_serv = async () => {
  const url = `${urlinc}/od/sat_catalogue/units`;
  try {
    const response = await axios.get(url, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const put_concepts_sub_taxes = async (id_concept_subtable,id_Impueston, data) => {
  const url = `${urlinc}/od/concepts/${id_concept_subtable}/taxes/${id_Impueston}`;
  try {
    const response = await axios.put(url, data, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post_concepts_taxes = async (id_conceptADD, data) => {
  const url = `${urlinc}/od/concepts/${id_conceptADD}/taxes`;
  try {
    const response = await axios.post(url, data, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//carta porte
export const post_invoices = async (data) => {
  const url = `${urlinc}/od/invoices`;
  try {
    const response = await axios.post(url, data, {
       headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_category_sat = async () => {
  const url = `${urlsat}/od/sat_category_codes`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_section_sat = async (section) => {
  const url = `${urlsat}/od/sat_category_codes/sections?category=${section}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_section_codes_sat = async (id) => {
  const url = `${urlsat}/od/sat_category_codes/sections/codes?id=${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_unit_codes_sat = async (id) => {
  const url = `${urlsat}/od/sat_units_codes`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
